import service from "@/request/index";

export function uploadFile(data) {
  return service.post("file/upload", data);
}

export function appDownload(params) {
  return service.get(`web/download-address/download`, {
    params,
  });
}
