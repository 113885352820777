<template>
  <div class="download">
    <div class="download-select">
      <p class="title">下载e路播客户端</p>
      <p class="sub-title">e路播在手，出海不发愁</p>
      <div class="download-list">
        <div class="platform-select">
          <div class="active">
            <i class="iconfont icon-android"></i>
            <span>Android</span>
          </div>
          <div>
            <i class="iconfont icon-iOS"></i>
            <span>iOS(敬请期待)</span>
          </div>
          <div>
            <i class="iconfont icon-windows"></i>
            <span>Windows(敬请期待)</span>
          </div>
          <div>
            <i class="iconfont icon-MAC-79"></i>
            <span>Mac(敬请期待)</span>
          </div>
        </div>

        <div class="platform-intro">
          <p class="platform-title">下载 Android 版 e路播应用</p>
          <div class="version-info">
            <p>版本：1.0.0</p>
            <p>更新：2022.9.30</p>
            <p>支持：Android 5.0+</p>
            <p>
              为您的安卓手机下载适用于Android
              版的e路播，一键连接轻松使用。急速全球直播，不卡顿
            </p>
          </div>
          <div class="download-btn">
            <a-button class="download-btn" @click="download">免费下载</a-button>
            <i class="iconfont icon-erweima"></i>
          </div>
          <div class="phone-img">
            <img src="@/assets/images/download-phone.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="download-intro">
      <p class="download-intro-title">3步轻松使用 Android 版e路播</p>
      <div class="download-intro-list">
        <div class="download-intro-item">
          <img src="@/assets/images/下载.png" />
          <p>
            <span>①</span>
            <span>下载</span>
          </p>
          <p>点击“免费下载”按钮或者手机扫码下载，</p>
          <p>下载完成后在手机端安装。</p>
        </div>
        <div class="download-intro-item">
          <img src="@/assets/images/登录.png" />
          <p>
            <span>②</span>
            <span>登录</span>
          </p>
          <p>打开app完后输入账号和密码登录。</p>
        </div>
        <div class="download-intro-item">
          <img src="@/assets/images/使用.png" />
          <p>
            <span>③</span>
            <span>使用</span>
          </p>
          <p>选择合适的线路，开启连接。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
import { appDownload } from "@/request/file";
export default {
  components: {
    [Button.name]: Button,
  },
  methods: {
    download() {
      appDownload({ osName: 1 }).then((res) => {
        window.open(res[0].uri);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.download {
  width: 100%;
  .download-select {
    width: 100%;
    height: 750px;
    margin-top: -124px;
    padding-top: 139px;
    background: url("../../assets/images/download-bg1.png") center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: #39425c;
      font-size: 44px;
      font-weight: Bold;
      margin-bottom: 24px;
    }
    .sub-title {
      color: #39425c;
      font-size: 18px;
      margin-bottom: 40px;
    }

    .download-list {
      width: 1440px;
      .platform-select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 80px;
        & > div {
          width: 357px;
          height: 80px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            background-color: rgba(255, 255, 255, 0.6);
            .iconfont,
            span {
              color: #3661eb;
            }
          }
          .iconfont {
            margin-right: 16px;
            &.icon-iOS,
            &.icon-windows {
              font-size: 32px;
            }
            &.icon-android {
              font-size: 44px;
            }
            &.icon-MAC-79 {
              font-size: 40px;
            }
          }
          span {
            color: #39425c;
            font-size: 20px;
            font-weight: Bold;
          }
        }
      }
      .platform-intro {
        width: 100%;
        height: 389px;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 60px 120px 48px 120px;
        color: #39425c;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 0px 0px 10px 10px;
        .platform-title {
          font-size: 20px;
          margin-bottom: 29px;
          font-weight: bold;
        }
        .version-info {
          width: 600px;
          p {
            font-size: 16px;
            margin-bottom: 0;
            line-height: 28px;
          }
        }

        .download-btn {
          margin-top: auto;
          display: flex;
          align-items: center;
          button {
            width: 240px;
            height: 44px;
            border-radius: 22px;
            background-color: #27bca5;
            color: #edfaff;
            font-size: 22px;
            margin-right: 24px;
            border: 0;
            outline: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .icon-erweima {
            font-size: 40px;
            color: #5b7cb4;
          }
        }

        .phone-img {
          width: 160px;
          height: 320px;
          position: absolute;
          top: 35px;
          right: 199px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .download-intro {
    width: 100%;
    height: 750px;
    background: url("../../assets/images/download-bg2.png") center no-repeat;
    background-size: cover;
    margin-bottom: -24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .download-intro-title {
      color: #39425c;
      font-size: 44px;
      margin: 79px 0 60px 0;
      font-weight: Bold;
    }
    .download-intro-list {
      width: 1440px;
      height: 520px;
      display: flex;
      justify-content: space-between;
      .download-intro-item {
        width: 420px;
        height: 100%;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(1) {
          img {
            width: 334px;
            margin-top: 77px;
            margin-bottom: 39px;
          }
        }
        &:nth-child(2) {
          img {
            width: 323px;
            margin-top: 91px;
            margin-bottom: 37px;
          }
        }
        &:nth-child(3) {
          img {
            width: 304px;
            margin-top: 103px;
            margin-bottom: 37px;
          }
        }
        p {
          &:nth-child(2) {
            color: #3661eb;
            font-size: 24px;
            font-weight: Bold;
            margin-bottom: 20px;
          }
          &:nth-child(3),
          &:nth-child(4) {
            color: #39425c;
            font-size: 18px;
            margin: 0;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
